import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/var/www/kecman.dev/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
import { format } from 'date-fns';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Flex = makeShortcode("Flex");
const Box = makeShortcode("Box");
const Badge = makeShortcode("Badge");
const Link = makeShortcode("Link");
const IconButton = makeShortcode("IconButton");
const Image = makeShortcode("Image");
const SourceList = makeShortcode("SourceList");
const Card = makeShortcode("Card");
const Text = makeShortcode("Text");
const Heading = makeShortcode("Heading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`helloWorld`}</h2>
    <h3>{`Hi I'm Nemanja, Front-end developer. nice to meet you.`}</h3>
    <p>{`Having worn many hats in my career between business developement, marketing management, 3D vizuelization, graphic design, I use my expirience to be more than just a coder.`}</p>
    <Flex sx={{
      flexWrap: 'wrap'
    }} mdxType="Flex">
  <Box sx={{
        width: ['100%', '40%', '40%'],
        mb: 3,
        pr: [0, 0, 4]
      }} mdxType="Box">
        <h2>{`getStack`}</h2>
  <Badge variant='primary' sx={{
          mr: 2
        }} mdxType="Badge"> Angular </Badge>
  <Badge variant='primary' sx={{
          mr: 2
        }} mdxType="Badge"> RxJs </Badge>
  <Badge variant='primary' sx={{
          mr: 2
        }} mdxType="Badge"> Node.js </Badge>
  <Badge variant='primary' sx={{
          mr: 2
        }} mdxType="Badge"> Sass </Badge>
  <Badge variant='primary' sx={{
          mr: 2
        }} mdxType="Badge"> Redux </Badge>
  <Badge variant='success' sx={{
          mr: 2
        }} mdxType="Badge"> Nest.js </Badge>
  <Badge variant='success' sx={{
          mr: 2
        }} mdxType="Badge"> Express.js </Badge>
  <Badge variant='success' sx={{
          mr: 2
        }} mdxType="Badge"> React.js </Badge>
  <Badge variant='success' sx={{
          mr: 2
        }} mdxType="Badge"> React Native </Badge>
  <Badge variant='success' sx={{
          mr: 2
        }} mdxType="Badge"> Vue.js </Badge>
  </Box>
  <Box sx={{
        width: ['100%', '40%', '40%'],
        mb: 3
      }} mdxType="Box">
        <h2>{`contactMe`}</h2>
  <Link href="https://github.com/k3cman" mdxType="Link">
  <IconButton mdxType="IconButton">
  <Image src='images/github.png' mdxType="Image" />
  </IconButton>
  </Link>
  <Link href="https://www.linkedin.com/in/k3cman/" mdxType="Link">
  <IconButton mdxType="IconButton">
  <Image src='images/linked_in.png' mdxType="Image" />
  </IconButton>
  </Link>
  <Link href="mailto:kecman.dev@gmail.com" mdxType="Link">
  <IconButton mdxType="IconButton">
  <Image src='images/email.png' mdxType="Image" />
  </IconButton>
  </Link>
  <br />
  <Link href="images/cv.pdf" mdxType="Link">
  Get Resume / >
  </Link>
  </Box>
    </Flex>
    <h2>{`getPosts`}</h2>
    <SourceList filter="posts" mdxType="SourceList">
  {posts => {
        if (posts.length > 0) {
          return <Flex sx={{
            flexWrap: "wrap",
            ml: theme => `-${theme.space[2]}px`,
            mr: theme => `-${theme.space[2]}px`
          }} mdxType="Flex">
      {posts.filter(edge => !edge.node.frontmatter.isPrivate).sort((a, b) => new Date(b.node.frontmatter.date) - new Date(a.node.frontmatter.date)).splice(0, 3).map((edge, index) => {
              const {
                frontmatter: {
                  title,
                  date,
                  featuredImage,
                  url,
                  misc
                },
                fields: {
                  slug
                }
              } = edge.node;
              console.log(featuredImage);
              return <Box key={index} sx={{
                mb: 3,
                maxWidth: ["100%", "50%", "33.333%"],
                width: ["100%", "50%", "33.333%"]
              }} mdxType="Box">
            <Link href={slug} sx={{
                  textDecoration: 'none'
                }} mdxType="Link">
              <Card sx={{
                    ml: 2,
                    mr: 2
                  }} mdxType="Card"> 
                
                <Box sx={{
                      p: 3
                    }} mdxType="Box">
                <Text sx={{
                        fontSize: 0,
                        color: 'muted'
                      }} mdxType="Text">{format(new Date(date), 'd-MMM-u')}</Text>
                <Heading variant="styles.h4" sx={{
                        color: 'text'
                      }} mdxType="Heading">{title}</Heading>
                <Text sx={{
                        textDecoration: 'underline'
                      }} mdxType="Text">View project /> </Text>
                </Box>
               
              </Card>
            </Link>
          </Box>;
            })}
    </Flex>;
        } else {
          return <Text sx={{
            fontSize: 0,
            color: 'muted',
            marginBottom: 3
          }} mdxType="Text">No posts found.</Text>;
        }
      }}
    </SourceList>
    <h2>{`getProjects`}</h2>
    <SourceList filter="projects" mdxType="SourceList">
  {posts => {
        if (posts.length > 0) {
          return <Flex sx={{
            flexWrap: "wrap",
            ml: theme => `-${theme.space[2]}px`,
            mr: theme => `-${theme.space[2]}px`
          }} mdxType="Flex">
      {posts.filter(edge => !edge.node.frontmatter.isPrivate).sort((a, b) => new Date(b.node.frontmatter.date) - new Date(a.node.frontmatter.date)).splice(0, 3).map((edge, index) => {
              const {
                frontmatter: {
                  title,
                  date,
                  featuredImage,
                  url,
                  misc
                },
                fields: {
                  slug
                }
              } = edge.node;
              console.log(featuredImage);
              return <Box key={index} sx={{
                mb: 3,
                maxWidth: ["100%", "50%", "33.333%"],
                width: ["100%", "50%", "33.333%"]
              }} mdxType="Box">
            <Link href={slug} sx={{
                  textDecoration: 'none'
                }} mdxType="Link">
              <Card sx={{
                    ml: 2,
                    mr: 2
                  }} mdxType="Card"> 
                
                <Box sx={{
                      p: 3
                    }} mdxType="Box">
                <Text sx={{
                        fontSize: 0,
                        color: 'muted'
                      }} mdxType="Text">{format(new Date(date), 'd-MMM-u')}</Text>
                <Heading variant="styles.h4" sx={{
                        color: 'text'
                      }} mdxType="Heading">{title}</Heading>
                <Text sx={{
                        textDecoration: 'underline'
                      }} mdxType="Text">View project /> </Text>
                </Box>
               
              </Card>
            </Link>
          </Box>;
            })}
    </Flex>;
        } else {
          return <Text sx={{
            fontSize: 0,
            color: 'muted',
            marginBottom: 3
          }} mdxType="Text">No projects found.</Text>;
        }
      }}
    </SourceList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      